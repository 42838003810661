<template>
  <div class="loginpage">
    <div class="loginup">
      <!-- <div class="loginimg">
        <img class="homeimg" src="@/static/logo.jpg" alt="" />
      </div> -->
      <div>
        <!-- <h4>信息管理平台</h4> -->
        <!-- <h4 style="margin-top: -15px">登录</h4> -->
      </div>
    </div>
    <div class="logincenter">
      <div class="background">
        <img :src="imgSrc" alt="" />
      </div>
      <el-form
        :model="loginData"
        label-width="150px"
        class="form"
        style="margin-top: 50px"
      >
        <div class="loginbody">
          <div class="logininput">
            <el-form :model="loginData">
              <div>
                <i
                  class="el-icon-user"
                  style="float: left; margin-top: 10px; font-size: 20px"
                ></i>
                <el-input
                  class="inputrow"
                  placeholder="请输入账号"
                  v-model="loginData.name"
                  clearable
                ></el-input>
              </div>

              <br />
              <div>
                <i
                  class="el-icon-lock"
                  style="float: left; margin-top: 10px; font-size: 20px"
                ></i>
                <el-input
                  class="inputrow"
                  placeholder="请输入密码"
                  v-model="loginData.password"
                  show-password
                ></el-input>
              </div>
              <br />
            </el-form>
            <div>
              <i
                class="el-icon-circle-check"
                style="float: left; margin-top: 10px; font-size: 20px"
              ></i>
              <el-input
                class="inputrow"
                placeholder="请输入验证码"
                v-model="canvasId"
                clearable
              ></el-input>
              <div class="confirm_code">
                <canvas
                  id="confirm_id"
                  :width="contentWidth"
                  :height="contentHeight"
                  @click="changeConfirmNum()"
                ></canvas>
              </div>
            </div>
            <!-- <el-checkbox class="rem" v-model="checked">记住我</el-checkbox> -->
            <span class="login" @click="gotoLogin">登录</span>
            <el-button
              type="primary"
              @click="submitForm('loginData')"
              style="width: 90%; font-size: 15px; margin-left: 30px"
              >注册</el-button
            >
          </div>
        </div>
      </el-form>
    </div>
    <!-- <div class="logindown">
      <h7>为国家做贡献，为社会创财富，为学校促发展，为家庭谋幸福！</h7>
    </div> -->
  </div>
</template>
<script>
// import { setCookie, getCookie, delCookie } from '../api/cookie.js'
export default {
  name: "Register",

  props: {
    // 字体最小
    fontSizeMin: {
      type: Number,
      default: 16,
    },
    // 字体最大
    fontSizeMax: {
      type: Number,
      default: 40,
    },
    // 背景颜色最小值
    backgroundColorMin: {
      type: Number,
      default: 140,
    },
    // 背景颜色最大值
    backgroundColorMax: {
      type: Number,
      default: 250,
    },
    // 图片宽度
    contentWidth: {
      type: Number,
      default: 220,
    },
    // 图片高度
    contentHeight: {
      type: Number,
      default: 80,
    },
    // 字体颜色最大值
    fontColorMax: {
      type: Number,
      default: 252,
    },
    // 字体颜色最小值
    fontColorMin: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {
      imgSrc: require("@/static/loginback.jpg"),
      loginData: {
        name: "",
        password: "",
        role: 0,
      },
      input: "",
      checked: "",
      canvasId: null,
      confirmNum: "6678",
    };
  },
  methods: {
    gotoLogin() {
      this.$router.push("/");
    },
    //点击登录调用方法

    changeConfirmNum() {
      let str = "";
      for (let i = 0; i < 4; i++) {
        str += Math.floor(Math.random() * 10).toString();
      }
      this.confirmNum = str;
    },
    submitForm(formName) {
      //判断验证码是否正确
      if (this.canvasId == null || this.confirmNum != this.canvasId) {
        this.$message.error("验证码不正确");
        return;
      }
      // var name = parseInt(this.loginData.username);
      // var pass = parseInt(this.loginData.password);
      // if ((this.checked = true)) {
      //传入账号名，密码，和保存天数3个参数
      // this.setCookie(name, pass, 30);
      // }
      //接口
      var url = "/user/Register";
      this.$axios
        .post(url, this.loginData, { emulateJSON: true })
        .then((res) => {
          if (res.data.status != "OK") {
            alert("输入有误，请重新输入");
            this.loginData.name = "";
            this.loginData.password = "";
            return;
          } else {
            alert("注册成功！");
            localStorage.setItem("userid", res.data.userid);
            this.$router.push("/");
          }
        });
    },

    // //设置cookie
    // setCookie(c_name, c_pwd, exdays) {
    //   var exdate = new Date(); //获取时间
    //   exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * exdays); //保存的天数
    //   //字符串拼接cookie
    //   window.document.cookie =
    //     "username" + "=" + c_name + ";path=/;expires=" + exdate.toGMTString();
    //   window.document.cookie =
    //     "userPwd" + "=" + c_pwd + ";path=/;expires=" + exdate.toGMTString();
    // },
    // //读取cookie
    // getCookie: function () {
    //   if (document.cookie.length > 0) {
    //     var arr = document.cookie.split("; "); //这里显示的格式需要切割一下自己可输出看下
    //     for (var i = 0; i < arr.length; i++) {
    //       var arr2 = arr[i].split("="); //再次切割
    //       //判断查找相对应的值
    //       if (arr2[0] == "username") {
    //         this.loginData.username = arr2[1]; //保存到保存数据的地方
    //       } else if (arr2[0] == "userPwd") {
    //         this.loginData.password = arr2[1];
    //       }
    //     }
    //   }
    // },

    // //////////////////。以下是验证码的部分////////////////
    // //清除cookie
    // clearCookie: function () {
    //   this.setCookie("", "", -1); //修改2值都为空，天数为负1天就好了
    // },
    // 生成随机数(min-max)之间
    makeRandom(max, min) {
      return Math.floor(Math.random() * (max - min) + min);
    },
    // 生成随机颜色(这里颜色通过rgb())
    makeBackgroundColor(max, min) {
      let r = this.makeRandom(max, min);
      let g = this.makeRandom(max, min);
      let b = this.makeRandom(max, min);
      return `rgb(${r},${g}, ${b})`;
    },
    // 绘制canvas
    drawCtx() {
      let canvasId = document.getElementById("confirm_id");
      let ctx = canvasId.getContext("2d");
      // 先清除画布
      ctx.clearRect(0, 0, this.contentWidth, this.contentHeight);
      // 定义基线
      ctx.textBaseline = "bottom";
      // 绘制背景
      ctx.fillStyle = this.makeBackgroundColor(
        this.backgroundColorMax,
        this.backgroundColorMin
      );
      ctx.fillRect(0, 0, this.contentWidth, this.contentHeight);
      // 绘制文字
      // 这里遍历字符串需要拿到索引，所以不能用for-of
      for (let i = 0; i < this.confirmNum.length; i++) {
        this.drawText(ctx, this.confirmNum[i], i);
      }
      this.drawLine(ctx);
      this.drawDot(ctx);
    },
    // 绘制文字
    // 我们接受一个画布对象，字符串，和索引值（用来设置位置）
    drawText(ctx, char, i) {
      // 给文字设置填充色
      ctx.fillStyle = this.makeBackgroundColor(
        this.fontColorMax,
        this.fontColorMin
      );
      // 设置字体大小
      ctx.font = `${this.makeRandom(
        this.fontSizeMax,
        this.fontSizeMin
      )}px makeRandom`;
      // 起点位置横坐标
      // 横坐标通过索引来设置
      let x = (i + 1) * (this.contentWidth / (this.confirmNum.length + 1));
      // 纵坐标来进行随机（保证在当前画布内即可）
      let y = this.makeRandom(this.contentHeight - 10, this.fontSizeMax);
      // 旋转角度
      let dep = this.makeRandom(60, -60);
      // console.log("x", x, "y", y);
      ctx.translate(x, y);
      ctx.rotate((dep * Math.PI) / 180);
      ctx.fillText(char, 0, 0);
      //恢复坐标原点和旋转角度（注意这里恢复的时候需要根据设置旋转角度和偏移距离的顺序来恢复）
      ctx.rotate((-dep * Math.PI) / 180);
      ctx.translate(-x, -y);
    },
    // 我们通过设置一些干扰线和干扰点来进行对画布的数据的干扰
    // 绘制干扰线（这里我设置的直线，大家也可以自己进行设置一些曲线什么的）
    drawLine(ctx) {
      // 定义干扰线条数
      // 这里我定义的干扰线条数在（2-10）条
      let lineMax = Math.floor(Math.random() * (10 - 2) + 2);
      for (let i = 0; i <= lineMax; i++) {
        // 线条的颜色
        ctx.strokeStyle = this.makeBackgroundColor(
          this.fontColorMax,
          this.fontColorMin
        );
        ctx.beginPath();
        // 这里对于起始点进行随机的设置
        ctx.moveTo(
          this.makeRandom(this.contentWidth, 0),
          this.makeRandom(this.contentHeight, 0)
        );
        // 设置结束点位置
        ctx.lineTo(
          this.makeRandom(this.contentWidth, 0),
          this.makeRandom(this.contentHeight, 0)
        );
        ctx.stroke();
      }
    },
    // 绘制干扰点
    drawDot(ctx) {
      // 这里我定义的干扰点个数是（5-35）个
      let dotMax = Math.floor(Math.random() * (35 - 5) + 5);
      for (let i = 0; i <= dotMax; i++) {
        // 这里自定义点的半径，建议不要过大
        let radius = this.makeRandom(5, 1);
        ctx.beginPath();
        // arc()是进行曲线的绘制
        //arc(x, y, radius, sAngle, eAngle, counterclockwise)
        // x,y为圆心坐标，radius--半径，sAngle--起始角度（弧度）,eAngle--结尾角度(弧度),
        //counterclockwise --顺时针还是逆时针(boolean值可选)
        ctx.arc(
          this.makeRandom(0, this.contentWidth),
          this.makeRandom(0, this.contentHeight),
          radius,
          0,
          2 * Math.PI
        );
        // 给圆随机进行填充色
        ctx.fillStyle = this.makeBackgroundColor(
          this.fontColorMax,
          this.fontColorMin
        );
        ctx.fill();
        ctx.stroke();
      }
    },
  },
  ////////////////////////////////////////////////////
  //页面加载调用获取cookie值
  mounted() {
    // this.getCookie();
    this.drawCtx();
    // console.log(this.confirmNum)
    this.changeConfirmNum();
  },
  watch: {
    // 这里我们监听confirmNum，来触发canvas绘制方法,数字不同，画布不同
    confirmNum: "drawCtx",
  },
};
</script>

<style>
.loginup {
  margin-top: 200px;
  width: 80%;
  float: left;
  margin-left: -40px;
}
.logincenter {
  width: 100%;
  /* height: 100%; */
}
.loginpage {
  display: flex;
  flex-direction: column;
  justify-self: center;
  align-items: center;
}
.background img {
  width: 100%;
  height: 55%;
  /* height: 450px; */
  /**宽高100%是为了图片铺满屏幕 */
  z-index: -1; /*z-index，是用来确定元素层叠关系的，值大的元素显示在值小的元素的上面*/
  position: absolute;
}

.loginbody {
  /* z-index: 1; */
  /*z-index，是用来确定元素层叠关系的，值大的元素显示在值小的元素的上面*/
  /* position: absolute; */
  width: 550px;
  height: 350px;
  margin: auto;
  /* margin-top: -50px; */
  /* margin-left: 800px; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: space-around;
  align-items: center;
  float: right;

  /* background: white; */
  /* border-radius: 2%; */
}

.inputrow {
  /* display: inline; */
  width: 350px !important;
  height: 50px;
  margin-left: 15px;
}

.logininput {
  /* height: 400px; */
  margin-right: 20px;
  width: 400px;
}

.homeimg {
  /* flex: 1; */
  height: 60px;
  width: 60px;
  float: left;
  margin-left: -90px;
  margin-top: 15px;
}
.confirm_code {
  width: 80px;
  display: inline;
  margin-left: 80px;
  height: 50px;
}
#confirm_id {
  width: 200px;
  height: 80px;
  margin-top: 4px;
  margin-left: 25px;
}
.logindown {
  margin-top: 200px;
  font-family: sans-serif;
  font-size: 15px;
  /* float: right; */
}
.login {
  margin-left: 350px;
  font-size: 15px;
  cursor: pointer;
  text-decoration: underline;
}
</style>
